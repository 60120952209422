import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { Welcome } from 'src/ui/containers/landing/Welcome';
import { ConnectionDetails } from 'src/ui/containers/launchpad/dashboard/ConnectionDetails';
import { NewUserLanding } from 'src/ui/containers/launchpad/dashboard/NewUserLanding';
import { StardogApps } from 'src/ui/containers/launchpad/dashboard/StardogApps';
import {
  Connection,
  connectionInvalid,
  setConnectionBrowserAuthFailed,
  setConnectionUnauthorized,
} from 'src/ui/features/connection';
import { showConnectionDialog } from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useReauthenticateSsoConnectionMutation,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useVerifyConnection } from 'src/ui/hooks/connection';
import { useOnError } from 'src/ui/hooks/graph';
import {
  isBrowserAuthFailureSelector,
  isUnauthorizedSelector,
} from 'src/ui/selectors/connection';
import * as copy from 'src/ui/templates/copy';
import {
  doDesignerRedirect,
  doExplorerRedirect,
  doStudioRedirect,
  redirectToUrl,
} from 'src/ui/utils/window';

type TParams = { id?: string };

export const LaunchpadDashboard: React.VFC = () => {
  const { data, isLoading } = useSavedConnectionsQuery(graphQLClient);

  const { mutate: verifyConnection } = useVerifyConnection();
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  const [
    healthCheckErrorMessage,
    setHealthCheckErrorMessage,
  ] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const isBrowserAuthFailure = useAppSelector(isBrowserAuthFailureSelector);
  const isUnauthorized = useAppSelector(isUnauthorizedSelector);
  const history = useHistory();
  const params = useParams<TParams>();
  const [
    currentConnection,
    setCurrentConnection,
  ] = React.useState<GraphConnection | null>(null);

  const onError = useOnError();

  // istanbul ignore next
  const {
    mutate: reauthenticateSSOConnectionMutation,
  } = useReauthenticateSsoConnectionMutation(graphQLClient, {
    onError,
    onSuccess: (data) => {
      if (data?.reauthenticateSSOConnection) {
        redirectToUrl(data?.reauthenticateSSOConnection?.redirect_url);
      }
    },
  });

  // istanbul ignore next
  React.useEffect(() => {
    if (data?.listConnections) {
      if (params.id) {
        const connection =
          data.listConnections.find((c) => c?.index === Number(params.id)) ||
          null;
        setCurrentConnection(connection);
      } else if (data.listConnections.length > 0 && !currentConnection) {
        const firstConnection = data.listConnections[0];
        setCurrentConnection(firstConnection);
        if (history.location.pathname !== `/u/${firstConnection?.index}`) {
          history.push(`/u/${firstConnection?.index}`);
        }
      } else if (
        data.listConnections.length === 0 &&
        history.location.pathname !== '/'
      ) {
        history.push('/');
      }
    }
  }, [data, params, currentConnection, history]);

  // istanbul ignore next
  React.useEffect(() => {
    if (!currentConnection || isValidated || isUnauthorized) {
      return;
    }
    verifyConnection(currentConnection as Connection, {
      onSuccess: () => setIsValidated(true),
      onError: (_error) => {
        if (currentConnection.useBrowserAuth) {
          // If useBrowserAuth was enabled and validate connection failed,
          // show banner warning for this
          dispatch(setConnectionBrowserAuthFailed(true));
        } else if (currentConnection.useConnectionSSO) {
          // If they are using sso, then there they are most likely not
          // authorized or some other error with communication
          dispatch(setConnectionUnauthorized(true));
        } else {
          // If they are using Stardog token it has expired and they need
          // re-enter their password to get a new token.
          dispatch(setConnectionUnauthorized(true));
          dispatch(connectionInvalid(currentConnection?.index));
          dispatch(showConnectionDialog());
        }
      },
    });
  }, [
    isValidated,
    isUnauthorized,
    currentConnection,
    verifyConnection,
    dispatch,
  ]);

  React.useEffect(() => {
    setIsValidated(false);
    dispatch(setConnectionBrowserAuthFailed(false));
    dispatch(setConnectionUnauthorized(false));
    setHealthCheckErrorMessage('');
  }, [currentConnection, dispatch]);

  // istanbul ignore next
  const handleReauthenticateSSOConnection = () => {
    if (currentConnection) {
      reauthenticateSSOConnectionMutation({
        input: { connection_id: currentConnection?.id },
      });
    }
  };

  // istanbul ignore next
  const handleReauthenticateUserPasswordConnection = () => {
    if (currentConnection) {
      dispatch(connectionInvalid(currentConnection?.index));
      dispatch(showConnectionDialog());
    }
  };

  const connections =
    data?.listConnections?.filter((c): c is GraphConnection => c !== null) ??
    [];

  if (isLoading) {
    return <Loading />;
  }

  if (connections.length === 0) {
    return <NewUserLanding />;
  }

  return (
    <div>
      <Welcome
        titleText={copy.components.launchpad.dashboard.welcome}
        subText=""
        className="welcome-container-voicebox"
      />
      {currentConnection ? (
        <>
          <ConnectionDetails
            connection={currentConnection}
            onReauthenticateSSOConnection={handleReauthenticateSSOConnection}
            onReauthenticateUserPasswordConnection={
              handleReauthenticateUserPasswordConnection
            }
            isUnauthorized={isUnauthorized}
            isBrowserAuthFailure={isBrowserAuthFailure}
          />

          <StardogApps
            currentIndex={currentConnection.index}
            goToExplorer={doExplorerRedirect}
            goToStudio={doStudioRedirect}
            goToDesigner={doDesignerRedirect}
          />
        </>
      ) : null}
    </div>
  );
};
