import {
  AnchorButton,
  Icon,
  IconSize,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import * as React from 'react';

import { LAUNCHPAD_ACCOUNT_MENU_BUTTON } from 'src/ui/constants/testIds';
import { useProfile } from 'src/ui/hooks/connection';
import { components } from 'src/ui/templates/copy';
import { colors } from 'src/ui/templates/styles/colors';
import { redirectToUrl } from 'src/ui/utils/window';

interface AccountMenuProps {
  username: string | undefined;
}
const { accountMenu: accountMenuCopy } = components.launchpad;

export const AccountMenu: React.VFC<AccountMenuProps> = ({ username }) => {
  const handleLogout = () => {
    redirectToUrl('/auth/logout');
  };

  return (
    <Menu>
      <MenuDivider title={username} />
      <MenuItem
        icon={IconNames.LOG_OUT}
        onClick={handleLogout}
        text={accountMenuCopy.logout}
      />
    </Menu>
  );
};

export const AccountMenuButton: React.VFC = () => {
  const { data } = useProfile();
  const [isConnected, setIsConnected] = React.useState(false);

  React.useEffect(() => {
    if (data?.profile?.is_authenticated) {
      setIsConnected(true);
    }
  }, [data]);

  const handleLogin = () => redirectToUrl('/login');

  const menu = isConnected ? (
    <AccountMenu username={data?.profile?.username || 'unknown'} />
  ) : (
    <Menu>
      <MenuItem
        icon={IconNames.LOG_IN}
        onClick={handleLogin}
        text={accountMenuCopy.login}
      />
    </Menu>
  );

  return (
    <Popover2 content={menu} position={Position.BOTTOM} className="pop-over">
      <AnchorButton
        large
        minimal
        icon={
          <Icon
            data-testid={LAUNCHPAD_ACCOUNT_MENU_BUTTON}
            color={isConnected ? colors.connected : undefined}
            icon={IconNames.USER}
            iconSize={IconSize.LARGE}
          />
        }
      />
    </Popover2>
  );
};
