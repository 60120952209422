import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';

import * as ConnectionActions from 'src/ui/features/connection';
import {
  createPartnerConnection,
  deletePartnerConnectionDetail,
  deletePartnerDataSource,
  editPartnerConnection,
} from 'src/ui/features/partnerconnection';
import { VoiceboxApp } from 'src/ui/graph/types';

export const enum DialogType {
  CONNECTION = 'CONNECTION',
  PARTNER_CONNECTION = 'PARTNER_CONNECTION',
  PORTAL_REDIRECT = 'PORTAL_REDIRECT',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CREATE_PROFILE = 'CREATE_PROFILE',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  UNSUPPORTED_BROWSER = 'UNSUPPORTED_BROWSER',
  CREATE_API_TOKEN = 'CREATE_API_TOKEN',
  CREATE_VOICEBOX_APP = 'CREATE_VOICEBOX_APP',
  UPDATE_VOICEBOX_APP = 'UPDATE_VOICEBOX_APP',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  SSO_CONNECTION = 'SSO_CONNECTION',
}

type CreateApiTokenDialogData = {
  appId?: string;
};

type UpdateVoiceboxAppDialogData = {
  app: VoiceboxApp;
};

type CreateCustomerSSOConnectionDialogData = {
  connectionName: string;
};

type DialogData =
  | { type: DialogType; data?: never }
  | { type: DialogType.CREATE_API_TOKEN; data: CreateApiTokenDialogData }
  | {
      type: DialogType.UPDATE_VOICEBOX_APP;
      data: UpdateVoiceboxAppDialogData;
    }
  | {
      type: DialogType.SSO_CONNECTION;
      data: CreateCustomerSSOConnectionDialogData;
    };

const persistentDialogs = new Set<DialogType | null>([
  DialogType.CREATE_PROFILE,
  DialogType.CREATE_VOICEBOX_APP,
  DialogType.UNSUPPORTED_BROWSER,
]);

const queueableDialogs = new Set<DialogType>([
  DialogType.CONNECTION,
  DialogType.CREATE_PROFILE,
  DialogType.UNSUPPORTED_BROWSER,
]);

export interface UiState {
  dialog: DialogData | null;
  nextDialogQueue: DialogData[];
  initialVisit: boolean;
  verifiedUser: boolean;
  isLeftNavbarExpanded: boolean;
  isStardogEmployee: boolean;
  isVoiceboxOpen: boolean;
  isVoiceboxSidebarExpanded: boolean;
  loggedinUserEmail: string | null;
  unsupportedBrowser: boolean;
  userId: string | null;
  acknowledgedUnsupportedBrowser: boolean;
}

const initialState = (): UiState => ({
  dialog: null,
  nextDialogQueue: [],
  initialVisit: true,
  verifiedUser: true,
  isLeftNavbarExpanded: true,
  isStardogEmployee: false,
  isVoiceboxOpen: false,
  isVoiceboxSidebarExpanded: true,
  loggedinUserEmail: null,
  unsupportedBrowser: false,
  userId: null,
  acknowledgedUnsupportedBrowser: false,
});

const openOrQueueDialog = (
  state: Draft<UiState>,
  dialog: DialogData,
  force?: boolean
) => {
  if (state.dialog && force) {
    state.nextDialogQueue.push(state.dialog);
    state.dialog = dialog;
  } else if (state.dialog !== null && queueableDialogs.has(dialog.type)) {
    const isDialogQueued =
      state.dialog.type === dialog.type ||
      state.nextDialogQueue.find(
        /* istanbul ignore next */
        (nextDialog) => nextDialog.type === dialog.type
      );
    if (!isDialogQueued) {
      state.nextDialogQueue.push(dialog);
    }
  } else {
    state.dialog = dialog;
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    closedDialog: (state) => {
      if (!state.nextDialogQueue.length) {
        state.dialog = null;
      } else {
        const nextDialogType = state.nextDialogQueue.shift();
        state.dialog = nextDialogType || null;
      }
    },
    isStardogEmployee: (state) => {
      state.isStardogEmployee = true;
    },
    openedDialog: (state, action: PayloadAction<DialogData>) => {
      openOrQueueDialog(state, action.payload);
    },
    profileUpdated: (state) => {
      state.initialVisit = false;
      state.dialog = null;
    },
    returningUser: (state) => {
      state.initialVisit = false;
    },
    unverifiedUser: (state) => {
      state.verifiedUser = false;
    },
    usingUnsupportedBrowser: (state) => {
      state.unsupportedBrowser = true;
    },
    acknowledgeUsingUnsupportedBrowser: (state) => {
      state.acknowledgedUnsupportedBrowser = true;
    },
    /* istanbul ignore next */
    showAddNewDialog: (state) => {
      openOrQueueDialog(state, { type: DialogType.CONNECTION });
    },
    showInitialDialog: (state) => {
      if (state.initialVisit) {
        openOrQueueDialog(state, { type: DialogType.CREATE_PROFILE });
      }
    },
    showConnectionDialog: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      openOrQueueDialog(state, { type: DialogType.CONNECTION }, action.payload);
    },
    showVerifyEmailDialog: (state) => {
      state.dialog = { type: DialogType.VERIFY_EMAIL };
      openOrQueueDialog(state, { type: DialogType.VERIFY_EMAIL });
    },
    showUnsupportedBrowserDialog: (state) => {
      if (state.unsupportedBrowser && !state.acknowledgedUnsupportedBrowser) {
        openOrQueueDialog(state, { type: DialogType.UNSUPPORTED_BROWSER });
      }
    },
    showCreateApiTokenDialog: (
      state,
      action: PayloadAction<CreateApiTokenDialogData>
    ) => {
      openOrQueueDialog(state, {
        type: DialogType.CREATE_API_TOKEN,
        data: action.payload,
      });
    },
    showCreateVoiceboxAppDialog: (state) => {
      openOrQueueDialog(state, { type: DialogType.CREATE_VOICEBOX_APP });
    },
    showUpdateVoiceboxAppDialog: (
      state,
      action: PayloadAction<UpdateVoiceboxAppDialogData>
    ) => {
      openOrQueueDialog(state, {
        type: DialogType.UPDATE_VOICEBOX_APP,
        data: action.payload,
      });
    },
    showAddSSOConnection:
      /* istanbul ignore next */
      (state) =>
        /* istanbul ignore next */
        {
          openOrQueueDialog(state, {
            type: DialogType.SSO_CONNECTION,
          });
        },

    toggledLeftNavbar: (state, action: PayloadAction<boolean | undefined>) => {
      state.isLeftNavbarExpanded =
        action.payload ?? !state.isLeftNavbarExpanded;
    },
    toggledVoiceboxSidebar: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isVoiceboxSidebarExpanded =
        action.payload ?? !state.isVoiceboxSidebarExpanded;
    },
    toggledVoicebox: (state, action: PayloadAction<boolean>) => {
      state.isVoiceboxOpen = action.payload;
    },
    loggedinUserEmail: (state, action: PayloadAction<string | null>) => {
      state.loggedinUserEmail = action.payload;
    },
    userId: (state, action: PayloadAction<string | null>) => {
      state.userId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // When some actions are done we need to close any open dialog
    builder.addCase(ConnectionActions.setConnectionIndex.type, (state) => {
      if (state.dialog && persistentDialogs.has(state.dialog.type)) {
        return;
      }

      state.dialog = null;
    });
    builder.addCase(ConnectionActions.clearConnection.type, (state) => {
      if (state.dialog && persistentDialogs.has(state.dialog.type)) {
        return;
      }

      state.dialog = null;
    });
    builder.addCase(ConnectionActions.clearEditIndex.type, (state) => {
      if (state.dialog && persistentDialogs.has(state.dialog.type)) {
        return;
      }

      state.dialog = null;
    });
    // Open the Connection Dialog when we are editing/deleting a existing connection
    builder.addCase(ConnectionActions.editConnection.type, (state) => {
      state.dialog = { type: DialogType.CONNECTION };
    });
    builder.addCase(ConnectionActions.deleteConnection.type, (state) => {
      state.dialog = { type: DialogType.CONNECTION };
    });
    builder.addCase(createPartnerConnection.type, (state) => {
      state.dialog = { type: DialogType.PARTNER_CONNECTION };
    });
    builder.addCase(editPartnerConnection.type, (state) => {
      state.dialog = { type: DialogType.PARTNER_CONNECTION };
    });
    builder.addCase(deletePartnerDataSource.type, (state) => {
      state.dialog = { type: DialogType.PARTNER_CONNECTION };
    });
    builder.addCase(deletePartnerConnectionDetail.type, (state) => {
      state.dialog = { type: DialogType.PARTNER_CONNECTION };
    });
  },
});

export const {
  closedDialog,
  isStardogEmployee,
  openedDialog,
  profileUpdated,
  returningUser,
  showInitialDialog,
  showAddNewDialog,
  showConnectionDialog,
  showAddSSOConnection,
  showVerifyEmailDialog,
  showUnsupportedBrowserDialog,
  showCreateApiTokenDialog,
  showCreateVoiceboxAppDialog,
  showUpdateVoiceboxAppDialog,
  toggledLeftNavbar,
  toggledVoicebox,
  toggledVoiceboxSidebar,
  usingUnsupportedBrowser,
  acknowledgeUsingUnsupportedBrowser,
  unverifiedUser,
  userId,
  loggedinUserEmail,
} = uiSlice.actions;

export const { getInitialState } = uiSlice;
