import { useRouteMatch } from 'react-router-dom';
import {
  PortalNavbarTabData,
  STARDOG_APPLICATIONS_TAB,
  VOICEBOX_TAB,
} from 'vet-bones/components/molecules/PortalNavbar';

import { graphQLClient } from 'src/ui/graph/graphQLClient';
import { useSavedConnectionsQuery } from 'src/ui/graph/types';

export const useLeftNavbarTabFromRoute = (): PortalNavbarTabData | null => {
  const { data } = useSavedConnectionsQuery(graphQLClient);

  const landingPageRouteMatch = useRouteMatch({
    exact: true,
    path: ['/u/:id(\\d+|demo)/', '/'],
  });

  const voiceboxRouteMatch = useRouteMatch({
    exact: true,
    path: ['/u/:id(\\d+)/voicebox/:conversation?'],
  });

  if (!data?.listConnections?.length) {
    return null;
  }

  if (landingPageRouteMatch) {
    return STARDOG_APPLICATIONS_TAB;
  }

  if (voiceboxRouteMatch) {
    return VOICEBOX_TAB;
  }

  return null;
};
