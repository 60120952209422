import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  KNOWLEDGE_CATALOG_TAB,
  NAVBAR_TABS_BY_ID,
  PortalNavbar,
  PortalNavbarTabData,
  STARDOG_APPLICATIONS_TAB,
  VOICEBOX_TAB,
} from 'vet-bones/components/molecules/PortalNavbar';
import { getStardogConnection } from 'vet-bones/utils';

import { useAppSelector } from 'src/ui/app/hooks';
import {
  LEFT_NAVBAR_SELECTOR,
  LEFT_NAVBAR_SELECTOR_ITEM,
} from 'src/ui/constants/testIds';
import { LaunchpadAppBody } from 'src/ui/containers/launchpad/LaunchpadAppBody';
import {
  useCurrentConnection,
  useSetConnectionIndex,
} from 'src/ui/hooks/connection';
import { useLeftNavbarTabFromRoute } from 'src/ui/hooks/leftNavbarTabFromRoute';
import { isLeftNavbarExpandedSelector } from 'src/ui/selectors/isLeftNavbarExpanded';
import * as copy from 'src/ui/templates/copy';
import { redirectToUrl } from 'src/ui/utils/window';

export const LaunchpadNavbarWrapper: React.VFC = () => {
  const history: History = useHistory();
  const routeMatch = useRouteMatch<{ id: string }>({
    path: '/u/:id(\\d+|demo)/',
  });

  const isLeftNavBarExpanded = useAppSelector(isLeftNavbarExpandedSelector());
  const connectionIndexFromRedux = useAppSelector(
    (state) => state.connection.connectionIndex
  );
  const { connection } = useCurrentConnection(
    routeMatch?.params.id && !Number.isNaN(parseInt(routeMatch.params.id))
      ? routeMatch.params.id
      : connectionIndexFromRedux.toString()
  );
  const stardogConnection = connection
    ? getStardogConnection(connection)
    : undefined;

  const leftNavbarTab = useLeftNavbarTabFromRoute();
  const setConnectionIndex = useSetConnectionIndex();

  const onSelectTab = React.useCallback(
    (tabData: PortalNavbarTabData) => {
      let path = tabData.redirectPath;

      if (tabData.id === STARDOG_APPLICATIONS_TAB.id && routeMatch?.params.id) {
        path = `/u/${routeMatch?.params.id}`;
        history.push(path);
      }
      if (tabData.id === VOICEBOX_TAB.id) {
        path = `/u/${connection?.index}${tabData.redirectPath}`;
        history.push(path);
      }
      if (tabData.id === KNOWLEDGE_CATALOG_TAB.id) {
        path = `/u/${connection?.index}${tabData.redirectPath}`;
        // navigate to path, not history because new app will be loaded
        redirectToUrl(path);
      }
    },
    [history, routeMatch, connection]
  );

  return (
    <div className="sd-horizontal">
      {leftNavbarTab ? (
        <div
          className={classNames('sd-portal-navbar-container', {
            'sd-portal-navbar-container--expanded': isLeftNavBarExpanded,
          })}
        >
          <PortalNavbar
            connectionInfo={
              connection?.index !== undefined
                ? { connectionIndex: connection.index }
                : undefined
            }
            copy={copy.components.leftNav}
            isExpanded={isLeftNavBarExpanded}
            onSelectConnection={(connectionItem) =>
              setConnectionIndex(
                connectionItem.index,
                NAVBAR_TABS_BY_ID[leftNavbarTab.id]?.redirectPath
              )
            }
            onSelectTab={onSelectTab}
            selectedTabId={leftNavbarTab.id}
            selectorItemTestId={LEFT_NAVBAR_SELECTOR_ITEM}
            selectorTestId={LEFT_NAVBAR_SELECTOR}
            stardogConnection={stardogConnection}
          />
        </div>
      ) : null}
      <LaunchpadAppBody />
    </div>
  );
};
