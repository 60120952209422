import {
  Alignment,
  AnchorButton,
  Button,
  Colors,
  Icon,
  Navbar,
  Position,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Popover2 } from '@blueprintjs/popover2';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useAppDispatch } from 'src/ui/app/hooks';
import { LearnMenu } from 'src/ui/components/LearnMenu';
import {
  NAV_CONTAINER,
  NAV_EXPAND_LEFT_NAVBAR_BUTTON,
  NAV_HELP_MENU,
} from 'src/ui/constants/testIds';
import { AccountMenuButton } from 'src/ui/containers/launchpad/AccountMenuButton';
import { toggledLeftNavbar } from 'src/ui/features/ui';
import { useLeftNavbarTabFromRoute } from 'src/ui/hooks/leftNavbarTabFromRoute';
import { copy } from 'src/ui/templates/copy';
import logo from 'src/ui/templates/images/Stardog_Cloud_Logo_Light.svg';
import { getFriendlyName } from 'src/ui/utils/window';

const title = getFriendlyName();

/* istanbul ignore next */
export const AppNavBar: React.VFC = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const leftNavbarTab = useLeftNavbarTabFromRoute();

  return (
    <Navbar className="sd-app-navbar" data-testid={NAV_CONTAINER}>
      <Navbar.Group align={Alignment.LEFT}>
        {leftNavbarTab ? (
          <Button
            minimal
            data-testid={NAV_EXPAND_LEFT_NAVBAR_BUTTON}
            icon={<Icon color={Colors.WHITE} icon={IconNames.LIST} />}
            onClick={() => dispatch(toggledLeftNavbar())}
          />
        ) : null}
        <Link className="sd-app-navbar__logo" to="/">
          <img src={logo} alt={copy.appNavBar.altLogo} title={title} />
        </Link>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Popover2
          content={<LearnMenu hideKits={true} />}
          position={Position.BOTTOM}
        >
          <AnchorButton
            data-testid={NAV_HELP_MENU}
            large
            minimal
            text="Learn Stardog"
            id="nav-help-menu"
          />
        </Popover2>
        <AccountMenuButton />
      </Navbar.Group>
    </Navbar>
  );
};
